export const highlights: Array<{ title: string, description: string }> = [
  {
    title: 'Intuitive and easy-to-use',
    description: 'No coding, CSS or HTML skills needed.',
  },
  {
    title: 'Data source',
    description:
      'Use a Picture library as data source or choose your own images.',
  },
  {
    title: 'Full-screen feature',
    description: 'Ability to switch to a full-screen view.',
  },
  {
    title: 'Modern site compatibility',
    description: `The web part is developed as a SPFx component which means it's compatible with the modern sites (i.e. Communication and Modern Team sites).`,
  },
  {
    title: 'Configurable',
    description: `Wide range of settings: autoplay, autoplay speed, a position of thumbnail, lazy loading, etc.`,
  },
  {
    title: 'Secure',
    description:
      'The Image Gallery is hosted on your SharePoint environment and no data is shared are sent to our servers or shared with third parties.',
  },
];

export const images: Array<{ src: string, width: number, height: number }> = [
  {
    src: '/images/image-gallery/ig-01-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 1',
  },
  {
    src: '/images/image-gallery/ig-02-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 2',
  },
  {
    src: '/images/image-gallery/ig-03-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 3',
  },
  {
    src: '/images/image-gallery/ig-04-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 4',
  },
  {
    src: '/images/image-gallery/ig-05-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 5',
  },
  {
    src: '/images/image-gallery/ig-06-min.jpeg',
    height: 1050,
    width: 1680,
    title: 'Image Gallery - 6',
  },
];
