import React, { useState, useCallback, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Layout from '../../components/layout';
import './index.sass';
import { List } from '../../components/list';
import { scroller } from 'react-scroll';
import { highlights, images } from '../../components/image-gallery/data';
import { Modal as NvModal } from '../../components/modal';
import TextField from '@material-ui/core/es/TextField/TextField';
import * as yup from 'yup';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SEO from '../../components/seo';
import SignUpSection from '../../components/signup';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#009cf3',
    },
  },
  typography: {
    fontSize: 17,
    fontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
  },
  overrides: {
    MuiInput: {
      root: {
        paddingBottom: '0.2rem',
        marginBottom: '0.3rem',
      },
      underline: {
        '&:hover:before': {
          borderBottomWidth: '1px !important',
        },
        '&:after': {
          borderBottom: '#009cf3 solid 1px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
      },
      shrink: {
        transform: 'translate(0, -4px) scale(0.75)',
      },
      error: {
        color: '#333 !important',
      },
    },
  },
});

const Index = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [url, setUrl] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [isSubmittingUrl, setIsSubmittingUrl] = useState(false);
  const [result, setResult] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [product, setProduct] = useState('');
  const [email, setEmail] = useState('');

  const openLightBox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightBox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const submit = e => {
    e.preventDefault();
    let formData = {
      first_name: firstName,
      surname: surname,
      product: product,
      email: email,
      sharepoint_url: url,
    };
    setIsSubmittingUrl(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'purchase-details',
        ...formData,
      }),
    })
      .then(() => setResult('success'))
      .catch(error => setResult(error.toString()))
      .finally(() => {
        setIsSubmittingUrl(false);
      });
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'buynow',
        },
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: '99',
                },
                description: 'Image Gallery - Regular license',
              },
            ],
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            setFirstName(details.payer.name.given_name);
            setSurname(details.payer.name.surname);
            setEmail(details.payer.email_address);
            setProduct(details.purchase_units[0].description);
            setDisplayModal(true);
          });
        },
      })
      .render('#paypal-regular-licence');

    window.paypal
      .Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'buynow',
        },
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: '299',
                },
                description: 'Image Gallery - Premium license',
              },
            ],
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            setFirstName(details.payer.name.given_name);
            setSurname(details.payer.name.surname);
            setEmail(details.payer.email_address);
            setProduct(details.purchase_units[0].description);
            setDisplayModal(true);
            setDisplayModal(true);
          });
        },
      })
      .render('#paypal-premium-licence');
  }, []);

  return (
    <Layout pageType="blog">
      <SEO
        keywords={[
          'sharepoint',
          'image',
          'gallery',
          'online',
          'spfx',
          'webpart',
        ]}
        title="Node Vision | Image Gallery for SharePoint Online"
      />
      <div className="image-gallery">
        <section className="heading">
          <h1>Image &nbsp;Gallery</h1>
        </section>
        <section className="landing">
          <div className="container">
            <div className="p1">
              Simple and easy-to-use image gallery component for SharePoint
            </div>
            <div className="p2">
              The Image Gallery allows you to display images from a SharePoint
              library or a custom image set.
            </div>
            <div className="gallery-1 nv-gallery">
              <Gallery
                photos={images
                  .map(x => ({ ...x, src: x.src.replace('.jpeg', '.jpg') }))
                  .slice(0, 3)}
                onClick={openLightBox}
                targetRowHeight={200}
                margin={6}
              />
            </div>
          </div>
          <div className="try-now-wrapper">
            <button
              className="is-button"
              onClick={() => {
                scroller.scrollTo('installation', {
                  smooth: true,
                  duration: 800,
                });
              }}
            >
              Try it now for free
            </button>
            <button
              className="is-button with-border with-white-bg"
              onClick={() => {
                scroller.scrollTo('purchase', {
                  smooth: true,
                  duration: 800,
                });
              }}
            >
              Buy now
            </button>
          </div>
        </section>
        <section className="highlights">
          <div className="container">
            <h2>Highlights</h2>
            <List data={highlights} />
          </div>
        </section>
        <section className="screenshots">
          <div className="container">
            <h2>Preview</h2>
            <div className="nv-gallery">
              <Gallery
                photos={images.map(x => ({
                  ...x,
                  src: x.src.replace('.jpeg', '.jpg'),
                }))}
                onClick={openLightBox}
                targetRowHeight={200}
                margin={6}
              />
            </div>
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightBox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={images.map(x => ({
                      ...x,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
            <div className="video-container-wrapper">
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/u4L4QknNjuY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </section>
        <section className="purchase" id="purchase">
          <div className="container">
            <h2>Buy licence</h2>
            <h3>
              Regular licence <span>99</span>
            </h3>
            <div className="licence-text">
              <ul className="list-big">
                <li>Perpetual license. No renewal needed.</li>
                <li>Includes all future updates</li>
                <li>
                  It allows you to use the product within a single SharePoint
                  web application (multiple site collections as long as they
                  have the same domain name).
                </li>
                <li>Doesn't include customization or installation</li>
              </ul>
              <div className="price-note">Price is in US dollars.</div>
            </div>
            <div className="pay-pal-container">
              <div id="paypal-regular-licence" />
            </div>
            <div className="licence-separator" />
            <h3>
              Premium license <span>299</span>
            </h3>
            <div className="licence-text">
              <ul className="list-big">
                <li>The same conditions as for Regular license</li>
                <li>
                  Includes 3 prepaid hours which can be used for customization,
                  installation, advisory, etc.
                </li>
              </ul>
              <div className="price-note">Price is in US dollars.</div>
            </div>
            <div className="pay-pal-container">
              <div id="paypal-premium-licence" />
            </div>
          </div>
        </section>
        <section className="installation" id="installation">
          <div className="container">
            <h2>Installation</h2>
            <div className="download-text">
              Download a full version of the Employee Directory and try it for
              free. No credit card required.
            </div>
            <a
              href="/download/node-vision-image-gallery.sppkg"
              className="is-button"
              onClick={() => {
                ga('send', 'event', {
                  eventCategory: 'image-gallery',
                  eventAction: 'download',
                  eventLabel: 'file download',
                  eventValue: 1,
                });
                return true;
              }}
            >
              Download
            </a>
            <div className="current-version">Current version: 1.0.1.0</div>
            <div className="instructions">
              <a
                href="https://youtu.be/XVeUh5_y_BA"
                className="link"
                target="_blank"
              >
                Installation instructions
              </a>
              <a
                href="https://youtu.be/3VSKytOonjw"
                className="link"
                target="_blank"
              >
                Upgrade instructions
              </a>
              <a
                href="https://youtu.be/7Q76bgBkBJI"
                className="link"
                target="_blank"
              >
                Product activation instructions
              </a>
            </div>
            <div className="supported-environments">
              The current version is compatible with{' '}
              <strong>SharePoint Online</strong> (both Classic and Modern pages)
              and the following browsers: Google Chrome, Microsoft Edge,
              Firefox, Safari. The Internet Explorer is no longer supported.
              <br />
              <br />
              For other SharePoint versions (2016 & 2019), please email us at{' '}
              <a href="mailto:info@nodevision.com.au" className="link">
                info@nodevision.com.au
              </a>
              .
            </div>
          </div>
        </section>
        <SignUpSection />
      </div>
      <NvModal open={displayModal} onClose={() => {}}>
        <>
          {isSubmittingUrl && (
            <div className="modal-content">Please wait...</div>
          )}
          {result === 'success' && (
            <>
              <div className="modal-content">
                <div className="thank-you-for-purchase">
                  Thank you. You will receive the product key within 24 hours.
                </div>
              </div>
              <div className="modal-button">
                <button
                  className="is-small"
                  onClick={() => {
                    setDisplayModal(false);
                    setResult('');
                  }}
                >
                  Close
                </button>
              </div>
            </>
          )}
          {result !== '' && result !== 'success' && (
            <>
              <div className="modal-content">
                <div className="thank-you-for-purchase">
                  There was an error while submitting the form.
                </div>
              </div>
              <div className="modal-button">
                <button
                  className="is-small"
                  onClick={() => {
                    setDisplayModal(false);
                    setResult('');
                  }}
                >
                  Close
                </button>
              </div>
            </>
          )}
          {!isSubmittingUrl && result === '' && (
            <>
              <div className="modal-content">
                <div className="thank-you-for-purchase">
                  Thank you for completing the purchase.
                  <br />
                  <br />
                  To generate a new license key for you, we need your SharePoint
                  URL.
                </div>
                <MuiThemeProvider theme={theme}>
                  <form
                    name="purchase-details"
                    method="post"
                    data-netlify="true"
                    onSubmit={submit}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="purchase-details"
                    />
                    <input type="hidden" name="first_name" value="" />
                    <input type="hidden" name="surname" value="" />
                    <input type="hidden" name="product" value="" />
                    <input type="hidden" name="email" value="" />
                    <TextField
                      label="SharePoint URL"
                      fullWidth
                      margin={'normal'}
                      placeholder="Enter your SharePoint URL"
                      onChange={e => {
                        setUrl(e.target.value);
                        setIsUrlValid(
                          yup
                            .string()
                            .required()
                            .url()
                            .isValidSync(e.target.value)
                        );
                      }}
                      value={url}
                      onBlur={() => {
                        setIsUrlValid(
                          yup
                            .string()
                            .required()
                            .url()
                            .isValidSync(url)
                        );
                      }}
                      error={!isUrlValid}
                      helperText={!isUrlValid && 'The URL is invalid.'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="sharepoint_url"
                    />
                  </form>
                </MuiThemeProvider>
              </div>
              <div className="modal-button">
                <button
                  className="is-small"
                  type="submit"
                  disabled={!isUrlValid || url === ''}
                  onClick={submit}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </>
      </NvModal>
      <form name="purchase-details" netlify netlify-honeypot="bot-field" hidden>
        <input type="text" name="first_name" />
        <input type="text" name="surname" />
        <input type="text" name="product" />
        <input type="text" name="email" />
        <input type="text" name="sharepoint_url" />
      </form>
    </Layout>
  );
};

export default Index;
